import {useFormik} from 'formik'
import {FC, useCallback, useEffect, useMemo, useState} from 'react'
import {useDispatch} from 'react-redux'
import {FormLabel, FormSelect, FormText, GButton} from 'src/app/components/Libs'
import {FormPhone} from 'src/app/components/Libs/Form/Custom'
import GIcon from 'src/app/components/Libs/Icon/GIcon'
import {IOnboarding} from 'src/app/models/Onboarding/Onboarding.models'
import OnboardingRedux from 'src/app/store/Onboarding/OnboardingRedux'
import {emailRegExp, phoneRegExp, phoneStartsWith62And8RegExp} from 'src/app/utils/input-utils'
import {v4 as uuidv4} from 'uuid'
import * as Yup from 'yup'
import AlertModal from '../AlertModal/AlertModal'
import FormLayout from '../Layout/FormLayout'
import AdminDetailLevel from './AdminDetailLevel'

const AdminSection: FC<IOnboarding> = ({state}) => {
  const dispatch = useDispatch()
  const [detailAdminModal, setDetailAdminModal] = useState(false)
  const [warningModal, setWarningModal] = useState(false)
  const [formChanges, setFormChanges] = useState(false)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      users: state.users as OnboardingRedux.TUserState[],
    },
    initialTouched: [
      {
        id: true,
        level: true,
        level_name: true,
        name: true,
        email: true,
        mobile_number: true,
      },
      {
        id: true,
        level: true,
        level_name: true,
        name: true,
        email: true,
        mobile_number: true,
      },
      {
        id: true,
        level: true,
        level_name: true,
        name: true,
        email: true,
        mobile_number: true,
      },
    ] as any,
    initialErrors: {
      users: state?.errors?.users as any,
    },
    validationSchema: Yup.object().shape({
      users: Yup.array().of(
        Yup.object().shape({
          level: Yup.string().required('Level admin wajib diisi'),
          level_name: Yup.string().nullable().notRequired().max(100, 'Maksimal karakter 100'),
          name: Yup.string()
            .required('Nama lengkap wajib diisi')
            .min(3, 'Minimal karakter 3')
            .max(256, 'Maksimal karakter 256'),
          email: Yup.string()
            .email('Format alamat email tidak valid')
            .required('Alamat email perusahaan wajib diisi')
            .matches(emailRegExp, 'Format alamat email tidak valid')
            .max(100, 'Maksimal karakter 100')
            .test('unique', 'Email sudah terpakai, mohon untuk masukkan email lain.', function (v) {
              return this.from?.[1].value.users.filter((e: any) => e.email === v).length === 1
            })
            .test(
              'not-same-as-profile',
              'Alamat email tidak boleh sama dengan alamat email admin.',
              function (value) {
                return value !== state?.admin_data?.email
              }
            ),
          mobile_number: Yup.string()
            .trim()
            .matches(phoneRegExp, 'Nomor telepon harus terdiri dari 10 - 13 digit')
            .matches(phoneStartsWith62And8RegExp, 'Digit pertama harus angka 8')
            .required('Nomor telepon wajib diisi')
            .max(14, 'Nomor telepon harus terdiri dari 10 - 13 digit')
            .test(
              'unique',
              'Nomor telepon sudah terpakai, mohon untuk masukkan Nomor telepon lain.',
              function (v) {
                return (
                  this.from?.[1].value.users.filter((e: any) => e.mobile_number === v).length === 1
                )
              }
            )
            .test(
              'not-same-as-profile',
              'Nomor telepon tidak boleh sama dengan nomor telepon di admin/agen.',
              function (value) {
                return value !== state?.admin_data?.mobile_number
              }
            ),
        })
      ),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      dispatch(OnboardingRedux.actions.setUsers(values.users))
      dispatch(OnboardingRedux.actions.setStep(4))
    },
  })

  const adminObj = useMemo(() => {
    return {
      id: uuidv4(),
      level: '',
      level_name: '',
      name: '',
      email: '',
      mobile_number: '',
    }
  }, [])

  const addAdmin = useCallback(() => {
    formik.setFieldValue('users', [...formik.values.users, adminObj])

    setTimeout(() => {
      window.scrollBy({
        top: 500,
        behavior: 'smooth',
      })
    }, 100)
  }, [formik, adminObj])

  const deleteAdmin = useCallback(
    (index: number) => {
      const updatedOptions = formik.values.users.filter((_: any, id: number) => id !== index)
      formik.setFieldValue(`users`, updatedOptions)
    },
    [formik]
  )

  const handleSkipStep = () => {
    if (formChanges) setWarningModal(true)
    else dispatch(OnboardingRedux.actions.setStep(4))
  }

  const handleWarningSkipStep = () => {
    dispatch(OnboardingRedux.actions.setUsers([adminObj]))
    dispatch(OnboardingRedux.actions.setStep(4))
  }

  const errorFnForm = (index: any, nameField: string) => {
    const {users} = formik.errors as any
    if (users && users[index] && users[index][nameField]) {
      return users[index][nameField]
    }
    return undefined
  }

  const defaultUser: any = {
    level: '',
    level_name: '',
    name: '',
    email: '',
    mobile_number: '',
  }

  const checkFormikValuesForChanges = useCallback(() => {
    const hasChanges = Object.keys(defaultUser).some((key) =>
      formik.values.users.some((user: any) => user[key] !== defaultUser[key])
    )

    setFormChanges(hasChanges)

    //eslint-disable-next-line
  }, [formik.values.users])

  useEffect(() => {
    checkFormikValuesForChanges()
    //eslint-disable-next-line
  }, [formik.values.users])

  const levelAdminOpt = useMemo(() => {
    const templateAdm = [
      {label: 'Admin Level 2', value: 'L2'},
      {label: 'Admin Level 3', value: 'L3'},
    ]

    if (state?.profile?.level === 'L1') {
      return [{label: 'Admin Level 1', value: 'L1'}, ...templateAdm]
    } else if (state?.profile?.level === 'L2') {
      return templateAdm
    } else {
      return []
    }
  }, [state?.profile?.level])

  const wordingAdmin = useMemo(() => {
    if (state?.profile?.level === 'L1') {
      return 'Platform ini memiliki tiga level admin, Anda merupakan admin level 1 tertinggi.'
    }

    if (state?.profile?.level === 'L2') {
      return 'Platform ini memiliki tiga level admin, Anda merupakan admin level 2 tertinggi.'
    }
  }, [state?.profile?.level])

  return (
    <FormLayout state={state}>
      <form
        className='w-full bg-white rounded shadow-lg pt-9'
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <div
          onClick={handleSkipStep}
          className='px-10 font-medium underline cursor-pointer text-end text-primary-500'
        >
          Lewati
        </div>

        <div className='w-full px-10 mt-10'>
          <div className='font-semibold text-fs-6 text-neutral-900'>
            Undang Admin Untuk Tim Anda
          </div>
          <div className='mt-[6px] text-neutral-600'>{wordingAdmin}</div>
        </div>

        {formik.values.users.length > 0 &&
          formik.values.users.map((data, index) => (
            <div key={data?.id} className='w-full pt-10 px-9'>
              <div className='flex items-center mb-6'>
                <div className='flex-grow border-t border-neutral-300'></div>
                <span className='mx-4 text-neutral-900'>Admin ke {index + 1}</span>
                <div className='flex-grow border-t border-neutral-300'></div>
              </div>

              <div>
                <div>
                  <FormLabel className='mb-2' required>
                    Level admin
                  </FormLabel>
                  <FormSelect
                    {...formik.getFieldProps(`users[${index}].level`)}
                    name={`users[${index}].level`}
                    placeholder='Pilih level admin'
                    options={levelAdminOpt}
                    changedValue={(v: any) => {
                      formik.setFieldValue(`users[${index}].level`, v.value)
                    }}
                    selectedValue={formik.values[`users`][index][`level`]}
                    error={errorFnForm(index, 'level')}
                    touched={true}
                  />
                  <div
                    onClick={() => setDetailAdminModal(true)}
                    className='flex gap-2 mt-2 cursor-pointer text-primary-500'
                  >
                    <GIcon icon='IconView' />
                    <span className='font-medium text-fs-9'>Lihat detail level admin</span>
                  </div>
                </div>

                <div className='mt-8'>
                  <FormLabel className='mb-2'>Nama divisi admin</FormLabel>
                  <FormText
                    {...formik.getFieldProps(`users[${index}].level_name`)}
                    name={`users[${index}].level_name`}
                    placeholder='Contoh: Finance, HR, Spv'
                    maxLength={256}
                    inputClassName='bg-white'
                    error={errorFnForm(index, 'level_name')}
                    touched={true}
                  />
                  <div className='flex mt-2 gap-x-2 text-fs-10 text-neutral-500'>
                    <div>
                      <GIcon icon='IconInfoOutline' />
                    </div>
                    Divisi merupakan penamaan level admin/role
                  </div>
                </div>

                <div className='mt-8'>
                  <FormLabel className='mb-2' required>
                    Nama lengkap admin sesuai identitas
                  </FormLabel>
                  <FormText
                    {...formik.getFieldProps(`users[${index}].name`)}
                    name={`users[${index}].name`}
                    placeholder='Masukkan nama lengkap admin sesuai identitas'
                    maxLength={256}
                    inputClassName='bg-white'
                    error={errorFnForm(index, 'name')}
                    touched={true}
                  />
                </div>

                <div className='mt-8'>
                  <FormLabel className='mb-2' required>
                    Alamat email admin
                  </FormLabel>
                  <FormText
                    {...formik.getFieldProps(`users[${index}].email`)}
                    name={`users[${index}].email`}
                    placeholder='Masukkan alamat email admin yang akan Anda undang'
                    maxLength={256}
                    inputClassName='bg-white'
                    error={errorFnForm(index, 'email')}
                    touched={true}
                  />
                  <div className='flex mt-2 gap-x-2 text-fs-10 text-neutral-500'>
                    <div>
                      <GIcon icon='IconInfoOutline' />
                    </div>
                    Admin akan menerima tautan email untuk melanjutkan proses pendaftaran.
                  </div>
                </div>

                <div className='mt-6'>
                  <FormLabel className='mb-2' required>
                    Nomor telepon admin yang akan Anda undang
                  </FormLabel>
                  <FormPhone
                    onChangeValue={(value) =>
                      formik.setFieldValue(`users[${index}].mobile_number`, value)
                    }
                    value={formik.values.users[index].mobile_number as string}
                    placeholder='Contoh: 81234567000'
                    inputClassName='bg-white'
                    error={errorFnForm(index, 'mobile_number')}
                    touched={true}
                  />
                </div>
              </div>

              <div className='flex items-center py-8 gap-7'>
                <div className='flex items-center flex-1 h-9'>
                  <div className='w-full flex items-center h-[1px] bg-neutral-300'></div>
                </div>

                {formik.values.users.length > 1 && (
                  <div
                    onClick={() => deleteAdmin(index)}
                    className='flex items-center gap-2 cursor-pointer text-danger h-9'
                  >
                    <GIcon icon='IconDelete' />
                    <span className='text-fs-9'>Hapus</span>
                  </div>
                )}

                {formik.values.users.length < 3 && formik.values.users.length - 1 === index && (
                  <div
                    onClick={addAdmin}
                    className='flex items-center gap-2 cursor-pointer text-primary-500 h-9'
                  >
                    <GIcon icon='IconAdd' />
                    <span className='text-fs-9'>Tambah Admin</span>
                  </div>
                )}
              </div>
            </div>
          ))}

        <div className='w-full border-t border-neutral-200'>
          <div className='px-10 py-5 text-end'>
            <GButton size={'large'} type='submit'>
              Lanjut
            </GButton>
          </div>
        </div>
      </form>

      <AdminDetailLevel
        show={detailAdminModal}
        onHide={() => setDetailAdminModal(false)}
        state={state}
      />

      <AlertModal modal={warningModal} setModal={setWarningModal} onLeave={handleWarningSkipStep} />
    </FormLayout>
  )
}

export default AdminSection
