import {useMutation} from '@tanstack/react-query'
import {useFormik} from 'formik'
import {FC, useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {FormLabel, FormPassword, GButton} from 'src/app/components/Libs'
import {PasswordFormUnique} from 'src/app/components/Widgets/PasswordFormUnique'
import {useHeaderToast} from 'src/app/context/ToastContext'
import {useErrorQuery} from 'src/app/hooks/error-query.hook'
import {useOnline} from 'src/app/hooks/online.hook'
import {useWebTitle} from 'src/app/hooks/title.hook'
import {resetPasswordUser, verificationUserToken} from 'src/app/services'
import * as Yup from 'yup'
import AuthScreens from '../../AuthScreens'

const resetSchema = Yup.object().shape({
  password: Yup.string()
    .max(256, 'Maksimal karakter 256')
    .matches(
      /^(?=.*[!@#$&*%^()_\-[\]\\/])(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
      'Pastikan password sesuai ketentuan'
    )
    .required('Password baru wajib diisi'),
  new_password: Yup.string()
    .test('equal', 'Password tidak sesuai', function (v) {
      // Don't use arrow functions
      const ref = Yup.ref('password')
      return v === this.resolve(ref)
    })
    .max(256, 'Maksimal karakter 256')
    .required('Ulangi password baru wajib diisi'),
})

const initialValues = {
  password: '',
  new_password: '',
}

export const CreateNewPassword: FC = () => {
  const history = useHistory()
  let query = new URLSearchParams(useLocation().search)
  const token = query.get('t') as string
  useWebTitle('Buat Password Baru')
  const {errorTemp} = useErrorQuery()
  const {addPageToasts} = useHeaderToast()
  const {isOnline, errorOffline} = useOnline()

  const [generatedToken, setGeneratedToken] = useState<string>('')

  const submitFn = useMutation({
    mutationFn: (payload: typeof initialValues) => {
      const val = {
        password: payload.new_password,
      }

      return resetPasswordUser(val, {Authorization: `Bearer ${generatedToken}`})
    },
    onSuccess: () => {
      addPageToasts({scheme: 'success', text: 'Password berhasil diperbarui'})
      formik.setSubmitting(false)
      history.replace(AuthScreens.LOGIN_EMAIL.PATH)
    },
    onError: (e) => errorTemp(e),
  })

  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: resetSchema,
    onSubmit: (values) => {
      if (isOnline) {
        submitFn.mutate(values)
      } else {
        addPageToasts({scheme: 'danger', text: errorOffline})
      }
    },
  })

  useEffect(() => {
    const payload = {
      token: token,
    }

    if (token) {
      verificationUserToken(payload)
        .then((res) => {
          const data = res?.data?.response_output?.detail?.token
          if (data === null) {
            history.replace(AuthScreens.LOGIN_EMAIL.PATH)
          }
          setGeneratedToken(data)
        })
        .catch(() => {
          history.replace(AuthScreens.LOGIN_EMAIL.PATH)
        })
    }
  }, [token, history])

  useEffect(() => {
    if (!token) history.replace(AuthScreens.LOGIN_EMAIL.PATH)
  }, [history, token])

  if (!token) return null

  return (
    <div data-testid='create-new-password-page' className='w-full'>
      <div className='mb-14'>
        <div className='mb-3 font-semibold text-fs-5 text-neutral-900'>Buat Password Baru</div>
        <div className='text-neutral-600'>Buat password baru untuk masuk ke akun Anda.</div>
      </div>

      <form className='w-full' onSubmit={formik.handleSubmit} noValidate>
        <div className='mb-8'>
          <FormLabel className='mb-2'>Password</FormLabel>
          <PasswordFormUnique
            {...formik.getFieldProps('password')}
            name='password'
            placeholder='Password Baru'
            maxLength={256}
            error={formik.errors.password}
            touched={formik.touched.password}
          />
        </div>

        <div className='mb-8'>
          <FormLabel className='mb-2'>Konfirmasi Password</FormLabel>
          <FormPassword
            {...formik.getFieldProps('new_password')}
            name='new_password'
            placeholder='Konfirmasi Password Baru'
            maxLength={256}
            error={formik.errors.new_password}
            touched={formik.touched.new_password}
          />
        </div>

        <GButton type='submit' size='large' className='w-full' loading={submitFn.isLoading}>
          Perbarui Password
        </GButton>
      </form>
    </div>
  )
}
