import {reduxBatch} from '@manaflair/redux-batch'
import {configureStore} from '@reduxjs/toolkit'
import {Persistor, persistStore} from 'redux-persist'
import createSagaMiddleware from 'redux-saga'
import {rootReducer, rootSaga} from './RootReducer'

// Create saga middleware
const sagaMiddleware = createSagaMiddleware()

// Configure store
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      thunk: true,
    }).concat(sagaMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
  enhancers: [reduxBatch],
})

// Run sagas
sagaMiddleware.run(rootSaga)

// Persistor setup
let _persistor: Persistor | null = null
export const persistor = (): Persistor => {
  if (!_persistor) {
    _persistor = persistStore(store)
  }
  return _persistor
}

// Export types
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export default store
