import {useMemo} from 'react'
import {IProfile, ModulePermissionQuery} from 'src/app/models'
import AdministrationScreens from 'src/app/modules/administration/routes/Screens'
import LogActivityScreens from 'src/app/modules/aktivitas-log/routes/Screen'
import DashboardScreens from 'src/app/modules/dashboard/routes/Screen'
import JobScreens from 'src/app/modules/job-tugas/routes/Screen'
import SettingScreens from 'src/app/modules/setting/routes/Screen'
import UserScreens from 'src/app/modules/user/routes/Screen'

export interface Menu {
  id?: string
  to?: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  children?: Menu[]
  module_permissions?: ModulePermissionQuery
  activePath?: string
  badge?: string
  badgeCircle?: boolean
  hidden?: boolean
  disabled?: boolean
}

export const useMenus = (user?: IProfile): Menu[] => {
  return useMemo(
    () =>
      [
        {
          children: [
            // DASHBOARD
            {
              to: DashboardScreens.DASHBOARD.PATH,
              icon: '/media/icons/IconDashboard.svg',
              title: DashboardScreens.DASHBOARD.TITLE,
              hidden: user?.level?.type === 'L0',
            },
            // USER
            {
              to: UserScreens.USER.PATH,
              icon: '/media/icons/IconUser.svg',
              title: UserScreens.USER.TITLE,
              hidden: user?.level?.type === 'L0',
              children: [
                {
                  to: UserScreens.USER_ADMIN_LIST.PATH,
                  activePath: UserScreens.USER_ADMIN.PATH,
                  title: UserScreens.USER_ADMIN.TITLE,
                  hasBullet: true,
                },
                {
                  to: UserScreens.USER_AGEN_LIST.PATH,
                  activePath: UserScreens.USER_AGEN.PATH,
                  title: UserScreens.USER_AGEN.TITLE,
                  hasBullet: true,
                },
              ],
            },
            // JOB & TUGAS
            {
              to: JobScreens.JOB_TUGAS_LIST_ACTIVE.PATH,
              activePath: JobScreens.JOB.PATH,
              icon: '/media/icons/IconJobTask.svg',
              title: JobScreens.JOB.TITLE,
              hidden: user?.level?.type === 'L0',
            },
            // AKTIVITAS LOG
            {
              to: LogActivityScreens.LOG_LIST_AGEN.PATH,
              activePath: LogActivityScreens.LOG_ACTIVITY.PATH,
              icon: '/media/icons/IconClock.svg',
              title: LogActivityScreens.LOG_ACTIVITY.TITLE,
              hidden: user?.level?.type !== 'L1',
            },
            // SETTING
            {
              to: SettingScreens.SETTING.PATH,
              icon: '/media/icons/IconSetting.svg',
              title: SettingScreens.SETTING.TITLE,
              hidden: user?.level?.type === 'L0',
              children: [
                // {
                //   to: SettingScreens.SETTING_UMUM.PATH,
                //   activePath: SettingScreens.SETTING_UMUM.PATH,
                //   title: SettingScreens.SETTING_UMUM.TITLE,
                //   hasBullet: true,
                //   hidden: user?.level?.type === 'L0',
                // },
                {
                  to: SettingScreens.SETTING_PERUSAHAAN.PATH,
                  activePath: SettingScreens.SETTING_PERUSAHAAN.PATH,
                  title: SettingScreens.SETTING_PERUSAHAAN.TITLE,
                  hasBullet: true,
                  hidden: user?.level?.type !== 'L1',
                },
                // {
                //   to: SettingScreens.SETTING_PAKET.PATH,
                //   activePath: SettingScreens.SETTING_PAKET.PATH,
                //   title: SettingScreens.SETTING_PAKET.TITLE,
                // hidden: user?.level?.type !== 'L1',
                //   hasBullet: true,
                // },
              ],
            },
            // CLIENT
            {
              to: AdministrationScreens.ORGANIZATION.PATH,
              icon: '/media/icons/IconMaster.svg',
              title: AdministrationScreens.ORGANIZATION.TITLE_ALT,
              hidden: user?.level?.type !== 'L0',
            },
          ],
        },
      ] as Menu[],
    [user]
  )
}
