import {ScreenProp} from 'src/app/models'
import {OrganizationScreen} from '../page/organization/Screen'

const AdministrationScreens: Record<string, ScreenProp> = {
  ADMINISTRATION: {
    PATH: '/administration',
    TITLE: 'Administration',
    TITLE_ALT: 'Administration',
  },

  ...OrganizationScreen,
}

export default AdministrationScreens
