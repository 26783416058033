import {Order} from 'src/app/models'

export enum EAdminStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  INVITED = 'INVITED',
}

export interface IAdmin {
  id: string
  name: string
  employee_number?: string
  division: string
  status: keyof typeof EAdminStatus
  type: string
}

export interface IAdminDetail {
  employee_number?: string
  name: string
  email: string
  mobile_number: string
  status: keyof typeof EAdminStatus
  last_activity: string
  division: {
    id: string
    code: string
    value: string
  }
  type: string
}

export interface IAdminRequest {
  employee_number?: string
  name: string
  email: string
  mobile_number: string
  division: string
}

export interface IAdminQuery {
  search?: string
  page?: number
  size?: number
  sort?: string
  order?: Order
  statuses?: string[]
  division_ids?: string[]
}

interface IAdminBulkValue {
  id?: string
  value: string
  is_valid: boolean
}
export interface IAdminBulkRequest {
  name: IAdminBulkValue
  email: IAdminBulkValue
  employee_number: IAdminBulkValue
  mobile_number: IAdminBulkValue
  division: IAdminBulkValue
  level: IAdminBulkValue
}
