import {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../components/Layout/FallbackView'
import AdministrationScreens from '../modules/administration/routes/Screens'
import LogActivityScreens from '../modules/aktivitas-log/routes/Screen'
import DashboardScreens from '../modules/dashboard/routes/Screen'
import JobScreens from '../modules/job-tugas/routes/Screen'
import OnboardingAgent from '../modules/onboarding/page/OnboardingAgent'
import OnboardingPage from '../modules/onboarding/page/OnboardingPage'
import OnboardingScreens from '../modules/onboarding/routes/Screen'
import ProfileScreens from '../modules/profile/routes/Screens'
import SettingScreens from '../modules/setting/routes/Screen'
import UserScreens from '../modules/user/routes/Screen'

export function PrivateRoutes() {
  const ProfilePage = lazy(() => import('../modules/profile/routes'))
  const DashboardPage = lazy(() => import('../modules/dashboard/routes'))
  const UserPage = lazy(() => import('../modules/user/routes'))
  const JobPage = lazy(() => import('../modules/job-tugas/routes'))
  const LogActivityPage = lazy(() => import('../modules/aktivitas-log/routes'))
  const SettingPage = lazy(() => import('../modules/setting/routes'))
  const AdministrationPage = lazy(() => import('../modules/administration/routes'))

  return (
    <>
      <Suspense fallback={<FallbackView />}>
        <Switch>
          <Route path={ProfileScreens.PROFILE.PATH} component={ProfilePage} />
          <Route path={DashboardScreens.DASHBOARD.PATH} component={DashboardPage} />
          <Route path={UserScreens.USER.PATH} component={UserPage} />
          <Route path={JobScreens.JOB.PATH} component={JobPage} />
          <Route path={LogActivityScreens.LOG_ACTIVITY.PATH} component={LogActivityPage} />
          <Route path={SettingScreens.SETTING.PATH} component={SettingPage} />

          {/* Administration hanya sementara */}
          <Route path={AdministrationScreens.ADMINISTRATION.PATH} component={AdministrationPage} />

          {/* KE HALAMAN ONBOARDING (AUTO LOGOUT) */}
          <Route path={OnboardingScreens.ONBOARDING.PATH} component={OnboardingPage} />
          <Route path={OnboardingScreens.ONBOARDING_AGENT.PATH} component={OnboardingAgent} />

          <Redirect from='/auth' to='/dashboard' />
          <Redirect exact from='/' to='/dashboard' />
          <Redirect to='/error/404' />
        </Switch>
      </Suspense>
    </>
  )
}
